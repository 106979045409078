<template>
  <div class="newest-wrap">
    <div v-if="!checkLoading && !showErrPage">
      <div class="newest-header">
        <span>{{$t('taskhub.history.title')}}</span>
        <span class="err-hash" v-if="gid === 4 && err_hash_num" @click="toHash"><svg-icon name="info"></svg-icon>{{$t('taskhub.history.err_hash')}}</span>
      </div>
      <div class="newest-content">
        <div class="newest-content-header">
          <switch-tab :list="switchList" :val="history.tasktype" @change="handleChangeTaskType" />
          <filter-option v-if="isMounted" pageType="private_filter" :extraFilters="extraFilters"></filter-option>
        </div>
        <div id="filterWrapper"></div>
        <Loading :loading="loading" v-if="loading" />
        <TemplateList
          v-else
          :columnAttr="columnAttr"
          :dataList="historyList"
          :isLoading="loading"
          :header="true"
          :hasCheckbox="true"
          :customHeader="true"
          class="history-list"
          :page="page"
          @public="handlePublic"
          @deleteFailed="id => deleteFailed(id)"
        >
          <template #table_header>
            <div class="history-list-header">
              <div class="history-list-btn-wrap">
                <button
                  :class="['btn-item', {disable: !canShare}]"
                  @click="handleShare('share', canShare)"
                  v-if="gid >= 2"
                >{{$t('taskhub.history.public')}}</button>
                <button
                  :class="['btn-item', {disable: !canCancelShare}]"
                  @click="handleShare('unshare', canCancelShare)"
                  v-if="gid >= 2"
                >{{$t('taskhub.history.private')}}</button>
                <button
                  :class="['btn-item', {disable: !checklist.length}]"
                  @click="handleDelete"
                >{{$t('taskhub.history.delete.label')}}</button>
                <Popover
                  @submit="handleShareTeam"
                  @close="handlePopoverClose"
                  ref="teamPop"
                  :teamIdsOfTask="teamIdsOfTask"
                  @choose="modifyTeamIdsOfTask"
                  :checklist="checklist"
                >
                </Popover>
              </div>
            </div>
          </template>
        </TemplateList>
      </div>
      <div class="pagination-wrap">
        <Pagination
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :totalSize="totalSize"
          :current_page="currentPage"
          :page-size="pageSize"
          customTotal
        />
      </div>
      <!-- <backTo-top isContainer=".router-view__wrap"></backTo-top> -->
      <el-dialog
        v-model="dialogVisible"
        :title="$t('taskhub.history.delete.title')"
        width="30%"
      >
        <div class="deleteContent">
          <svg-icon name="info"></svg-icon>
          <i18n-t keypath="taskhub.history.delete.warn" tag="span">
            <template #html>
              <font :color="$isDark() ? 'white' : 'black'">{{checklist.length > 50 ? 50 : checklist.length}}</font>
            </template>
          </i18n-t>
          <!-- <span style="line-height: 21px;">任务删除操作不可恢复，任何人将不再可见所选任务报告的所有内容，请确认是否删除 <font color="white">{{checklist.length > 50 ? 50 : checklist.length}}</font> 个任务？</span> -->
        </div>
        <div class="delete-tips" v-if="checklist.length > 50">{{$t('taskhub.history.delete.tips')}}</div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisible = false">{{$t('dialog.cancel')}}</el-button>
            <el-button type="primary" @click="deleteReportFn"
              >{{$t('dialog.delete.confirm')}}</el-button
            >
          </span>
        </template>
      </el-dialog>
      <el-dialog
        v-model="failedVisible"
        :title="$t('taskhub.history.failed.title')"
        width="30%"
      >
        <div class="deleteContent">
          <svg-icon name="info"></svg-icon>
          <span style="line-height: 21px;">{{$t('taskhub.history.failed.info')}}</span>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="failedVisible = false">{{$t('dialog.cancel')}}</el-button>
            <el-button type="primary" @click="deleteReportFn('failed')"
              >{{$t('dialog.delete.confirm')}}</el-button
            >
          </span>
        </template>
      </el-dialog>
    </div>
    <Loading :loading="checkLoading" v-if="checkLoading" />
    <div class="close-captch__wrap" v-if="showErrPage">
      <close-captch :showFooter="false"></close-captch>
    </div>
    <backTo-top isContainer=".router-view__wrap"></backTo-top>
  </div>
</template>

<script>
import closeCaptch from '@/components/common/closeCaptch.vue'
import TemplateList from '@/components/common/TemplateList'
import Pagination from '@/components/common/Pagination'
import { getTaskUserList, postTaskUserShare, postTaskDelete } from 'services/center'
import { postTeamShareTask } from 'services/team'
import { checkLogin } from 'services/auth'

import { mapMutations, mapGetters } from 'vuex'
import filterOption from '../component/filter'
import SwitchTab from '../component/SwitchTab.vue'
import Popover from './component/popover'
import emitter from '@/app/emitter.js'
import { t } from 'app/i18n'

// import list from './data.json'
export default {
  name: 'History',
  components: {
    TemplateList,
    Pagination,
    Popover,
    SwitchTab,
    filterOption,
    closeCaptch
  },
  data () {
    return {
      totalSize: 0,
      pageSize: 50,
      currentPage: Number(this.$route.query.page) || 1,
      historyList: [],
      loading: true,
      isMounted: false,
      showErrPage: false,
      checkLoading: true,
      switchList: [
        {
          label: t('taskhub.history.all'),
          value: ''
        },
        {
          label: t('taskhub.history.file'),
          value: 'file'
        },
        {
          label: 'URL',
          value: 'url'
        }
      ],
      columnAttr: [
        {
          label: '环境_时间',
          props: 'env_time',
          span: 5
        },
        {
          label: '类型_分值',
          props: 'type_score',
          span: 4
        },
        {
          label: '名称_类型_标签',
          props: 'name_type_tags',
          span: 5
        },
        {
          label: '哈希',
          props: 'hash',
          span: 6
        },
        {
          label: '公开私有',
          props: 'public',
          span: 3
        }
      ],
      extraFilters: {
        label: t('other.views.taskHub.taskStatus'),
        type: 'tab',
        option: [
          {
            label: t('other.views.taskHub.all'),
            value: 1
          },
          {
            label: t('other.views.taskHub.comlpleted'),
            value: 2
          },
          {
            label: t('other.views.taskHub.queuing'),
            value: 3
          },
          {
            label: t('other.components.report.dynamic_pending'),
            value: 4
          },
          {
            label: t('other.views.taskHub.testing'),
            value: 5
          },
          {
            label: t('other.views.taskHub.scanning'),
            value: 6
          },
          {
            label: t('other.views.taskHub.dynamicing'),
            value: 7
          },
          {
            label: t('other.views.taskHub.tested'),
            value: 8
          },
          {
            label: t('other.views.taskHub.testFailed'),
            value: 9
          }
        ]
      },
      initInterval: null,
      history: {
        score: '',
        tag: '',
        tasktype: '',
        filetype: '',
        hash: ''
      },
      page: 'history',
      isFilter: false,
      checklist: [],
      filter: {},
      dialogVisible: false,
      failedVisible: false,
      failedId: 0,
      err_hash_num: 0
    }
  },
  computed: {
    ...mapGetters({
      store_tasktype: 'history/tasktype',
      store_score: 'history/score',
      store_tag: 'history/tag',
      store_filetype: 'history/filetype',
      store_hash: 'history/hash',
      gid: 'gid'
    }),
    canShare () {
      return this.checklist.some(item => !item.is_public)
    },
    canCancelShare () {
      return this.checklist.some(item => item.is_public)
    },
    disableShareTeam () {
      return this.checklist.length !== 1
    },
    teamIdsOfTask () {
      const taskIdsSharedMap = {}
      this.checklist.map(item => {
        taskIdsSharedMap[item.id] = item.team_id
      })
      return taskIdsSharedMap
    }
  },
  methods: {
    ...mapMutations({
      setOptions: 'history/SET_OPTIONS'
    }),
    toHash() {
      const routeData = this.$router.resolve({ name: 'err-hash' })
      window.open(routeData.href, '_blank')
    },
    modifyTeamIdsOfTask(item, val) {
      if (val) {
        for (let i = 0; i < this.checklist.length; i++) {
          this.checklist[i].team_id.push(item.team_id)
        }
      } else {
        // this.checklist.map(v => v.team_id.filter(m => m === item.team_id))
        this.checklist.map(v => {
          v.team_id = v.team_id.filter(m => m !== item.team_id)
        })
      }
    },
    handleDelete() {
      if (!this.checklist.length) return
      this.dialogVisible = true
    },
    deleteFailed(id) {
      this.failedId = id
      this.failedVisible = true
    },
    deleteReportFn(type) {
      let task_ids = this.checklist.slice(0, 50).map(item => item.id)
      if (type === 'failed') {
        task_ids = [this.failedId]
      }
      postTaskDelete({ task_ids }).then(res => {
        if (res.code && res.code !== 0) {
          this.$store.commit('app/showMessage', { type: 'error', message: res.message, duration: 800 })
          this.dialogVisible = false
        } else {
          this.$store.commit('app/showMessage', { message: t('action.delete'), duration: 800 })
          this.dialogVisible = false
          this.failedVisible = false
          this.$emitter.emit(`${this.page}/resetChecked`)
          this.getTaskUserList(false)
        }
      })
    },
    handleShareTeam (share, unshare) {
      const taskIds = this.checklist.map(item => item.id)
      postTeamShareTask({ task_ids: taskIds, share, unshare }).then(res => {
        this.$store.commit('app/showMessage', { message: t('action.share'), duration: 800 })
        this.$refs.teamPop.clear()
        this.$emitter.emit(`${this.page}/resetChecked`)
        this.getTaskUserList(false)
      })
    },
    handleChangeTaskType(type) {
      this.history.tasktype = type
      this.setOptions({ tasktype: type })
      this.handleCurrentChange(1)
    },
    handlePopoverClose () {
    },
    handleSizeChange (size) {
      this.pageSize = size
      this.getTaskUserList()
    },
    handleCurrentChange (page) {
      this.currentPage = page
      this.getTaskUserList()
      this.$router.replace({ path: this.$route.path, query: { ...this.$route.query, page } })
    },
    async handlePublic (taskId, isPublic) {
      if (this.gid < 2) return
      await postTaskUserShare({ task_ids: [taskId], is_public: isPublic })
        .then(() => {
          this.$store.commit('app/showMessage', { message: t('action.handle'), duration: 800 })
          this.getTaskUserList(false)
        })
    },
    async handleShare (type, status) {
      if (!status) return
      const isShare = type === 'share'
      await postTaskUserShare({ task_ids: this.checklist.map(item => item.id), is_public: isShare })
        .then(res => {
          this.$store.commit('app/showMessage', { message: t('action.handle'), duration: 800 })
          this.$emitter.emit(`${this.page}/resetChecked`)
          this.getTaskUserList(false)
        })
    },
    async getTaskUserList (needloading) {
      if (needloading !== false) {
        this.loading = true
      }
      await getTaskUserList({
        page: this.currentPage,
        size: this.pageSize,
        category: this.store_tasktype,
        ...this.filter
      })
        .then(res => {
          this.historyList = res.task
          this.totalSize = res.total
          this.err_hash_num = res.hash_error_total
          if (needloading !== false) {
            this.loading = false
          }
        })
        .catch(err => {
          console.log(err)
          if (err.message === 'Request failed with status code 401') {
            this.showErrPage = true
          }
        })
    },
    init () {
      this.getTaskUserList()
    },
    getLoginStatus(flag) {
      checkLogin().then((res) => {
        this.checkLoading = false
        if (!res.is_login) {
          this.showErrPage = true
        } else {
          this.showErrPage = false
          // this.getTaskUserList()
        }
      })
    }
  },
  created () {
    this.initInterval = setInterval(() => {
      this.getTaskUserList(false)
    }, 120000)
  },
  mounted () {
    this.getLoginStatus()
    this.isMounted = true
    const that = this
    emitter.on('private_filter/filterChange', (v) => {
      this.filter = v
      this.getTaskUserList()
    })
    emitter.on('unlogin', () => {
      this.loading = false
    })
    this.$emitter.on(`${this.page}/checkedRowList`, (list) => {
      that.checklist = list
    })
    emitter.on('reloadResult', () => {
      this.showErrPage = false
      this.getTaskUserList()
    })
  },
  unmounted () {
    clearInterval(this.initInterval)
    this.$emitter.off(`${this.page}/checkedRowList`)
    emitter.off('reloadResult')
    emitter.off('private_filter/filterChange')
  }
}
</script>

<style lang="scss" scoped>
.newest-wrap {
  @import '@/styles/common.scss';
  min-height: calc(100vh - $footer-height - $header-height);
  .close-captch__wrap {
    min-height: calc(100vh - 116px);
    display: flex;
    align-items: center;
  }
  .newest-header {
    line-height: 46px;
    height: 46px;
    padding: 2px 24px;
    display: flex;
    span {
      font-size: 18px;
      color: var(--color-text-1);
      font-weight: 500;
      margin-right: 8px;
    }
    .err-hash {
      color: var(--color-text-2);
      cursor: pointer;
      font-size: 13px;
      font-weight: normal;
      position: relative;
      top: 1px;
      svg {
        color:#fa8c16;
        margin-right: 4px;
      }
    }
    .newest-header-info {
      font-size: 14px;
    }
  }
  .newest-content {
    padding: 0px 24px 6px;
    // height: calc(100vh - 278px);

    display: flex;
    flex-direction: column;
    .newest-content-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // padding: 0px 0px 6px 0;
      position: relative;
      :deep(.public-list-option) {
        position: absolute;
        top: 1px;
        right: 0;
      }
    }
  }
  .pagination-wrap {
    padding: 10px 3px 24px;
    text-align: right;
  }
  .list-pagination {
  }
}
.history-list {
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .history-list-btn-wrap {
      .btn-item {
        width: 88px;
        height: 32px;
        box-sizing: border-box;
        border: none;
        background: transparent;
        border-radius: 4px;
        color: var(--color-priamry);
        cursor: pointer;
        &:focus {
          outline: 0;
        }
        &:not(:last-child) {
          margin-right: 8px;
        }
        &.disable {
          color: var(--color-text-2);
          cursor: not-allowed;
        }
      }
    }
    .history-list-option {
      position: relative;
      top: 2px;
      display: flex;
      align-items: center;
      .putlic-list-tag {
        display: inline-block;
        padding: 0 27px 0 10px;
        margin-right: 6px;
        color: var(--color-text-1);
        height: 26px;
        line-height: 26px;
        box-sizing: border-box;
        font-size: 14px;
        background: #364984;
        border-radius: 4px;
        position: relative;
        &-name {

        }
        &-close {
          position: absolute;
          right: 10px;
          top: -1px;
          bottom: 0px;
          margin: auto;
          cursor: pointer;
        }
      }
      .option-filter {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        cursor: pointer;
      }
    }
  }
}
.deleteContent {
  display: flex;
  svg {
    margin-right: 8px;
    font-size: 20px;
    color:#fa8c16;
    // position: relative;
    // top: -3px;
  }
  span {
    line-height: 21px;
  }
}
.delete-tips {
  padding: 21px 0 0 21px;
  font-size: 13px;
  color: #fa8c16;
}
</style>
